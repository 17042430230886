import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import Helmet from '../components/Helmet'
import HeroSlider from '../components/HeroSlider'
import Section, { SectionTitle, SectionBody } from '../components/Section'
import PolicyCard from '../components/PolicyCard'
import Grid from '../components/Grid'
import ProductCard from '../components/ProductCard'

import heroSliderData from '../assets/fake-data/hero-slider'
import policy from '../assets/fake-data/policy'
import productData from '../assets/fake-data/products'
import productDataAnalysis from '../assets/fake-data/productsAnalysis'
import productDataAI from '../assets/fake-data/productsAI'

import banner from '../assets/images/banner.png'
import logo from '../assets/images/logoData101.png'
import { store } from '../redux/store';
import { isMobile, isDesktop } from '../utils/detectDevice'; // 디바이스 타입 읽어오기
import videoIntro from '../assets/videos/introduction.mp4';
import videoBetatest from '../assets/videos/betatest.mp4';

const Home = () => {
    // 어드민 전용 로그인 여부 검증용
    const [isAdminLogin, setIsAdminLogin] = useState('');
    const inputRef = useRef(null);
    const [profileID, setProfileID] = useState('');

    const video1Ref = useRef(null);
    const video2Ref = useRef(null);
    const [currentVideo, setCurrentVideo] = useState(1);

    //================퍼널 분석용 변수 세팅================//
    const [visitTime, setVisitTime] = useState('');
    const [deviceType, setDeviceType] = useState('');
    const [uuid, setUuid] = useState('');
    const [cateId, setCateId] = useState('');
    const pageName = "home";
    //================================================//

    // const [inputValue, setInputValue] = useState('');
    // const [isMatch, setIsMatch] = useState(false);


    // const handleChange = (event) => {
    //     setInputValue(event.target.value);
    // };

    // const handleSubmit = (event) => {
    //     event.preventDefault(); // 폼 제출 시 페이지 새로고침 방지
    //     let inputValue = inputRef.current.value;
    //     console.log(`inputValue: ${inputValue}`);
    //     if (inputValue == adminPW) {
    //     setIsMatch(true);
    //     console.log("값 일치");
    //     setInputValue("");

    //     } else {
    //     setIsMatch(false);
    //     setInputValue("");
    //     Swal.fire({
    //         icon: 'warning',
    //         title: 'PW 불일치',
    //         text: '관계자 외 접근 불가 페이지',
    //         confirmButtonText: '확인',
    //     });
    //     }
    // };

    console.log(`productData: ${productData}`);
    console.log(`Object.keys(productData): ${Object.keys(productData)}`);
    // 

    useEffect(() => {
        window.scrollTo(0, 0);

        //======================사용자ID저장==========================
        const storedProfileID = localStorage.getItem('profileID');
        setProfileID(storedProfileID || '');
        //==========================================================

        const storedIsAdminLogin = localStorage.getItem('isLoginAdmin');
        setIsAdminLogin(storedIsAdminLogin || '');

        // 첫 번째 비디오의 재생이 끝나면 두 번째 비디오로 전환
        // 비디오 끝 이벤트 핸들러 설정
        const handleVideoEnd = () => {
            const nextVideo = currentVideo === 1 ? 2 : 1;
            setCurrentVideo(nextVideo);
            playVideo(nextVideo);
        };

        const video1 = video1Ref.current;
        const video2 = video2Ref.current;

        if (video1 && currentVideo === 1) {
            video1.addEventListener('ended', handleVideoEnd);
            playVideo(1);
        }
        if (video2 && currentVideo === 2) {
            video2.addEventListener('ended', handleVideoEnd);
            playVideo(2);

        }

        return () => {
            if (video1) video1.removeEventListener('ended', handleVideoEnd);
            if (video2) video2.removeEventListener('ended', handleVideoEnd);
        };

    }, [currentVideo]);
    console.log(`profileID: ${profileID}`);
    console.log(`isAdminLogin: ${isAdminLogin}`);

    const handlePrevClick = () => {
        const newVideo = currentVideo === 1 ? 2 : currentVideo - 1;
        setCurrentVideo(newVideo);
        playVideo(newVideo);
    };

    const handleNextClick = () => {
        const newVideo = currentVideo === 2 ? 1 : currentVideo + 1;
        setCurrentVideo(newVideo);
        playVideo(newVideo);
    };

    const playVideo = (videoNumber) => {
        if (videoNumber === 1) {
            if (video1Ref.current) {
                video1Ref.current.play();
            }
            if (video2Ref.current) {
                video2Ref.current.pause();
                video2Ref.current.currentTime = 0;
            }
        } else if (videoNumber === 2) {
            if (video2Ref.current) {
                video2Ref.current.play();
            }
            if (video1Ref.current) {
                video1Ref.current.pause();
                video1Ref.current.currentTime = 0;
            }
        }
    };


    const handleVideo2Click = () => {
        const currentURL = "https://www.data101.io";
        const soruceName = "ryq1Z";
        const surveyURL = `${currentURL}/betatest?soruce=${soruceName}`;
        window.open(surveyURL, '_blank');
    };


    const MainLandingPage = () => {

        return (
            <Helmet title="Trang chủ">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
                        {currentVideo === 1 ? (
                            <video
                                ref={video1Ref}
                                width="100%"
                                height="100%"
                                autoPlay
                                muted
                                // style={{ display: currentVideo === 1 ? 'block' : 'none' }}
                            >
                                <source src={videoIntro} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <video
                                ref={video2Ref}
                                width="100%"
                                height="100%"
                                autoPlay
                                muted
                                onClick={handleVideo2Click}
                                // style={{ display: currentVideo === 2 ? 'block' : 'none', cursor: 'pointer' }}
                                style={{ cursor: 'pointer' }}
                            >
                                <source src={videoBetatest} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                        <div
                            style={{
                                position: 'absolute',
                                bottom: '10px',
                                right: '10px',
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                color: '#fff',
                                padding: '5px 10px',
                                borderRadius: '5px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <button
                                onClick={handlePrevClick}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    color: '#fff',
                                    fontSize: '18px',
                                    padding: '0 5px',
                                    
                                }}
                            >
                                &lt;
                            </button>
                            <span style={{ margin: '0 5px' }}>
                                {currentVideo} / 2
                            </span>
                            <button
                                onClick={handleNextClick}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    color: '#fff',
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                    padding: '0 5px',
                                }}
                            >
                                &gt;
                            </button>
                        </div>
                    </div>
                </div>

                {/* <div
                    style={{
                        display: 'flex',          // 부모 요소를 Flexbox로 설정
                        justifyContent: 'center', // 가로축에서 중앙 정렬
                        alignItems: 'center',     // 세로축에서 중앙 정렬 (필요 시 사용)
                        height: '100%',           // 필요 시 전체 높이를 사용
                    }}
                >
                    {currentVideo === 1 ? (
                        <video ref={video1Ref} 
                                width="80%"
                                height='auto'
                                autoPlay
                                muted>
                            <source src={videoIntro} type="video/mp4" />
                            현재 사용하고 계신 브라우저는 영상 재생을 지원하지 않습니다.
                        </video>
                    ) : (
                        <video
                            ref={video2Ref}
                            width="80%"
                            height='auto'
                            autoPlay
                            muted
                            onClick={handleVideo2Click}
                        >
                            <source src={videoBetatest} type="video/mp4" />
                            현재 사용하고 계신 브라우저는 영상 재생을 지원하지 않습니다.
                        </video>
                    )}
                    {/* <video
                    style={{ 
                        width: '100%', 
                        height: 'auto', 
                        display: 'block', 
                        borderRadius: '15px',
                    }}
                    playsInline
                    loop
                    autoPlay
                    muted
                >
                    <source src="/videos/mainvideo.mp4" type="video/mp4" /> 
                </video>
                </div> */}
                <br /><br />
                <Section>
                    <SectionTitle>
                        🔍 코딩 없이 데이터 수집/분석하기
                    </SectionTitle>
                    <SectionBody>
                        <Grid
                            col={4}
                            mdCol={2}
                            smCol={1}
                            gap={20}
                        >
                            {
                                productData.getProducts(3).map((item, index) => (
                                    <ProductCard
                                        key={index}
                                        img01={item.image01}
                                        img02={item.image02}
                                        name={item.title}
                                        price={Number(item.price)}
                                        description={item.description}
                                        descriptionTag={item.descriptionTag}
                                        slug={item.slug}
                                        categorySlug={item.categorySlug}
                                    />
                                ))
                            }
                        </Grid>
                    </SectionBody>
                </Section>
            </Helmet>
        )
    }

    return (
        <div>
            {/* {(isMatch ? <MainLandingPage /> : <InputForm />)} */}
            <MainLandingPage />
        </div>
    );
}

export default Home
